<script>
import Layout from "../../layouts/main"
import { computed, onMounted, reactive, ref, watch } from "vue"
import { getRaffles, syncContestStats } from "./api"
import FindRaffleNumbersModal from "./FindRaffleNumbersModal"
import TermsOfUseModal from "./terms-of-use-modal"
import { debounce } from "lodash"
import AppPagination from "../../../components/app-pagination"
import { useRouter } from 'vue-router'
import { isFreeAccount, showContestPayment } from '@/feature-check'
import { copyContestLink } from './utils'
import storage from '@/storage'
import { Toasts } from "../../../utils/toast-alerts"

const statusTypes = [
  {
    label: 'Ativo',
    value: 'ACTIVE'
  },
  {
    label: 'Finalizado',
    value: 'FINISHED'
  },
  {
    label: 'Pendente',
    value: 'STANDBY'
  },
  {
    label: 'Esgotado',
    value: 'SOLD_OFF',
  }
]

if (isFreeAccount()) {
  statusTypes.push({
    label: 'Aguardando pagamento',
    value: 'PAYMENT'
  })
}

let userListenEvents = false

export default {
  setup(){
    const router = useRouter()
    const loading = ref(false)
    const raffleList = ref([])
    const totalContests = ref(0)
    const page = ref(1)
    const pages = ref(0)
    let raffleData =ref(null)
    const user = storage.getItem('user')
    const filterRaffle = reactive({
      title: "",
      limit: 10,
      page: 1,
    })
    const filterBy = ref(["ACTIVE", "FINISHED", "STANDBY", "SOLD_OFF", "PAYMENT"])
    const updatingStats = ref([])

    const handleSearch = debounce(async (e) => {
      filterRaffle.title = e.target.value;
      await fetchRaffles(filterRaffle);
    }, 500);

    const allowCreate = computed(() => {
      if (isFreeAccount()) {
        const pendingPayment = raffleList.value.filter(c => c.status === 'PAYMENT')
        return pendingPayment.length === 0
      }
      return true
    })

    const isStatsUpdating = (contest) => {
      return updatingStats.value.includes(contest.id)
    }

    const updateStats = (contest) => {
      if (!isStatsUpdating(contest)) {
        updatingStats.value.push(contest.id)
        syncContestStats(contest.id).then((data) => {
          const index = raffleList.value.findIndex(({ id }) => id === contest.id)
          raffleList.value[index].stats = data
        }).finally(() => {
          const index = updatingStats.value.indexOf(contest.id)
          updatingStats.value.splice(index, 1)
        })
      }
    }

    const fetchRaffles = () => {
      loading.value = true;
      getRaffles({
        title: filterRaffle.title,
        page: page.value,
        filterBy: filterBy.value
      }).then((data) => {
        raffleList.value = data.data
        pages.value = data.last_page
        totalContests.value = data.total

        window.Echo.channel(`private-users.${user.id}`)
          .listen('.numbers.generated', (props) => {
              const target = raffleList.value.find(({ id }) => id === props.id)
              if (target) {
                target.current_task = null
              }
          })
      }).catch(() => {
        Toasts('error', 'Falha ao carregar os sorteios')
      }).finally(() => {
        loading.value = false;
      })
    }

    /** @todo include debounce */
    watch(filterBy, () => {
      fetchRaffles()
    })

    const syncContestPayment = (data, status) => {
      const contest = raffleList.value.find(({ id }) => id === data.id)
      if (contest) {
        contest.status = status
        contest.payment = data
      }
    }

    onMounted(() => {
      fetchRaffles()
      if (user && !userListenEvents) {
        userListenEvents = true
        // Utilizado para sincronizar os dados de
        // pagamento com o sorteio na listagem
        window.Echo.channel(`private-users.${user.id}`)
          .listen('.payment.requested', (data) => {
            syncContestPayment(data, 'PAYMENT')
          })
          .listen('.payment.confirmed', (data) => {
            syncContestPayment(data, 'CONFIRMED')
          })
          .listen('.contest.deleted', (data) => {
            setTimeout(() => {
              const index = raffleList.value.findIndex(({ id }) => id === data.id)
              if (index >= 0) {
                raffleList.value.splice(index, 1)
              }
            }, 3000)
          })
      }
    })

    function calcTotal(porcentValue, totalValue) {
      const intPorcentValue = parseFloat(porcentValue);
      return   parseInt(intPorcentValue * totalValue);
    }

    function moneyFormat ( number) {
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });

      return formatter.format(number);
    }

    const getTaskLabel = (contest) => {
      switch(contest.current_task) {
        case 'generating_numbers': return 'Gerando números'
        case 'deleting_numbers': return 'Excluindo números'
        default: return ''
      }
    }

    function sendRaffleData(raffle){
      raffleData.value = raffle
    }

    async function onSubmit() {
      await fetchRaffles()
      raffleData.value = null
    }

    return {
      calcTotal,
      router,
      moneyFormat,
      fetchRaffles,
      totalContests,
      raffleList,
      onSubmit,
      raffleData,
      sendRaffleData,
      handleSearch,
      filterRaffle,
      page,
      pages,
      statusTypes,
      filterBy,
      showContestPayment,
      copyContestLink,
      allowCreate,
      loading,
      updateStats,
      isStatsUpdating,
      getTaskLabel
    }
  },
  components: {
    AppPagination,
    Layout,
    FindRaffleNumbersModal,
    TermsOfUseModal
  },
}

</script>

<template>
  <Layout :pagetitle="'Meus sorteios'">
    <div class="card">
      <div class="row align-items-center">
        <div class="col">
          <div>
            <h5 class="card-header bg-transparent border-bottom text-uppercase">
              Sorteios ({{ totalContests }})
            </h5>
          </div>
        </div>
        <div class="col">
          <div
            class="
              card-header
              bg-transparent
              border-bottom
              d-flex
              flex-wrap
              justify-content-end
              mb-3"
          >
            <ul class="nav nav-pills" style="height: 35px;">
              <div v-if="!loading">
                <router-link
                  v-if="allowCreate"
                  :to="`/raffles-form/create`"
                  class="btn btn-primary"
                >
                  <i class="bx bx-plus me-1"></i> Novo Sorteio
                </router-link>
                <upgrade-trigger
                  v-else
                  title="Novo Sorteio"
                  class="btn btn-primary"
                  icon="bx bx-plus me-1"
                  style="width: 100%"
                />
              </div>
            </ul>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <h5 class="card-header pb-3">
              <div class="position-relative">
                <i
                  class="search-icon"
                  data-eva="search-outline"
                  data-eva-height="26"
                  data-eva-width="26"
                />
                <input
                  v-model="filterRaffle.title"
                  type="text"
                  style="min-width: 100%; max-width: 500px;"
                  class="form-control border-0 bg-light"
                  placeholder="Pesquisar sorteios"
                  @input="handleSearch"
                >
              </div>
            </h5>
          </div>
          <div class="col">
            <h5 class="card-header bg-transparent border-bottom">
              <form class="row align-items-center form-control">
                <div class="hstack gap-4">
                  <div
                    v-for="status in statusTypes"
                    :key="status.value"
                    class="form-check form-switch"
                  >
                    <label
                      :for="status.value"
                      class="form-check-label"
                    >
                      {{ status.label }}
                    </label>
                    <input
                      v-model="filterBy"
                      class="form-check-input"
                      :value="status.value"
                      :id="status.value"
                      type="checkbox"
                      checked
                    />
                  </div>
                </div>
              </form>
            </h5>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-xl-12" >
            <div class="tab-content">
              <div
                class="tab-pane active ms-2"
                id="product"
                role="tabpanel"
              >
                <div class="row" v-if="!allowCreate">
                  <div class="col">
                    <div
                      class="alert alert-danger fade show"
                      role="alert"
                      @click="showContestPayment(raffleList[0].payment)"
                      style="cursor: pointer;"
                    >
                      <i class="mdi mdi-block-helper me-2"></i>
                      Seu sorteio "{{ raffleList[0].title }}", encontra-se pendente de pagamento para publicação
                      - Clique aqui para resolver
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div
                    v-for="raffle in raffleList"
                    :key="raffle.id"
                    class="col-xl-6"
                  >
                    <div class="card">
                      <inner-element
                        v-if="['deleting_numbers','generating_numbers'].includes(raffle.current_task)"
                        spinner
                        :title="getTaskLabel(raffle)"
                      />
                      <div class="card-header">
                        <div class="btn-group" role="group" style="width: 100%;">
                          <router-link
                            type="button"
                            class="btn btn-outline-dark"
                            :to="{ name: 'raffles-update', params: { id: raffle.id } }"
                          >
                            <i class="fas fa-edit"></i>
                            <span class="d-none d-lg-block"> Editar </span>
                          </router-link>
                          <router-link
                            type="button"
                            class="btn btn-outline-dark"
                            :to="{ name: 'raffles-management', params: { id: raffle.id } }"
                          >
                            <i class="fas fa-cog"></i>
                            <span class="d-none d-lg-block"> Gerenciar </span>
                          </router-link>
                          <router-link
                            type="button"
                            class="btn btn-outline-dark"
                            :to="{ name: 'contest_orders', params: { id: raffle.id } }"
                          >
                            <i class="fas fa-receipt"></i>
                            <span class="d-none d-lg-block"> Pedidos </span>
                          </router-link>
                          <button
                            type="button"
                            class="btn btn-outline-dark"
                            @click.prevent="copyContestLink(raffle)"
                          >
                            <i class="mdi mdi-content-copy"></i>
                            <span class="d-none d-lg-block"> Copiar link </span>
                          </button>
                          <button
                            type="button"
                            class="btn btn-outline-dark"
                            data-bs-toggle="modal"
                            data-bs-target=".find-numbers-raffle"
                            @click.prevent="sendRaffleData(raffle)"
                          >
                            <i class="fas fa-search"></i>
                            <span class="d-none d-lg-block"> Buscar número </span>
                          </button>
                          <button
                            v-if="raffle.status === 'PAYMENT' && raffle?.payment?.pix_code"
                            type="button"
                            class="btn btn-outline-dark"
                            @click="showContestPayment(raffle.payment)"
                          >
                            <i class="fas fa-qrcode"></i>
                            <span class="d-none d-lg-block"> Efetuar pagamento </span>
                          </button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row align-items-center">
                          <div class="col-md-5">
                            <img :src="raffle.gallery[0] ? raffle.gallery[0].path : '/assets/images/product/img-1.jpg'" class="img-fluid rounded" alt="">
                          </div>
                          <div class="col-md-7">
                            <div class="mt-3 mt-lg-0">
                              <h4 class="mb-2 font-size-20 text-truncate">
                                <router-link :to="`/raffles-form/management/${raffle.id}`" :title="`Gerenciar ${raffle.title}`" class="text-dark">
                                  {{ raffle.title }}
                                </router-link>
                              </h4>
                              <ul class="list-unstyled ps-0 mb-0 mt-3">
                                <li>
                                  <h5 class="text-muted mb-2 text-truncate"> {{ $d(raffle.start_date, 'short') }}  </h5>
                                </li>
                                <li>
                                  <h5 class="text-muted mb-2 text-truncate"> {{ raffle.quantity }} </h5>
                                </li>
                              </ul>
                              <div class="mt-4">
                                <div class="">
                                  <p class="text-muted mb-2 text-truncate">
                                    Pagos: {{ raffle?.stats?.numbers_paid_count ?? 0 }} /
                                    {{ $n(raffle?.stats?.orders_confirmed_amount ?? 0, 'currency') }} Recebidos
                                  </p>
                                  <div class="progress mb-4">
                                    <div
                                      class="progress-bar bg-success"
                                      role="progressbar"
                                      :style="`width: ${raffle?.stats?.numbers_paid_percentage ?? 0}%`"
                                    >
                                    </div>
                                  </div>

                                  <p class="text-muted mb-2 text-truncate">
                                    Reservados: {{ raffle?.stats?.numbers_reserved_count ?? 0 }} /
                                    {{ $n(raffle?.stats?.orders_pending_amount ?? 0, 'currency') }} Previstos
                                  </p>
                                  <div class="progress mb-4">
                                    <div
                                      class="progress-bar bg-warning"
                                      role="progressbar"
                                      :style="`width: ${raffle?.stats?.numbers_reserved_percentage ?? 0}%`"
                                    >
                                    </div>
                                  </div>
                                  <p class="text-muted mb-2 text-truncate">
                                    Restantes: {{ raffle.stats?.numbers_free_count ?? 0 }} /
                                    {{ $n((raffle?.stats?.numbers_free_count ?? 0) * raffle.price, 'currency') }} Receber
                                  </p>
                                  <div class="progress mb-4">
                                    <div
                                      class="progress-bar bg-danger"
                                      role="progressbar"
                                      :style="`width: ${raffle?.stats?.numbers_free_percentage ?? 0}%`"
                                    >
                                    </div>
                                  </div>
                                </div>
                                <div style="text-align: right;">
                                  <b-button
                                    size="md"
                                    variant="light"
                                    pill
                                    style="width: 200px;"
                                    @click="updateStats(raffle)"
                                    :disabled="isStatsUpdating(raffle)"
                                  >
                                    <b-spinner
                                      v-if="isStatsUpdating(raffle)"
                                      small
                                    />
                                    {{ isStatsUpdating(raffle) ? '' : 'Atualizar estatísticas' }}
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <app-pagination
              class="pagination"
              v-model="page"
              :pages="pages"
              :range-size="1"
              @update:modelValue="fetchRaffles"
            />
            <find-raffle-numbers-modal
              :raffle="raffleData"
            >
            </find-raffle-numbers-modal>
            <terms-of-use-modal />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
