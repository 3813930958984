<script>
import { ref, toRefs } from "vue";
import { getFindNumber } from "./api";
import { Toasts } from "../../../utils/toast-alerts";

export default {
  name: 'findNumbersModal',
  props:{
    raffle:{
      type: Object
    },
  },
  setup(props)  {
    const { raffle } = toRefs(props)
    let numberRaffle = ref(null)
    let numberData = ref({})
    let notExistNumber = ref(false)

    async function submitFindNumber(){
      try {
        numberData.value = await getFindNumber({
          number: numberRaffle.value,
          contest_id: raffle.value.id
        })
        notExistNumber.value = true

      }catch (error) {
        Toasts('error', 'Falha ao carregar número')
      }
    }

    function cancelSubmit(){
      numberRaffle.value = null
      numberData.value = {}
      notExistNumber.value = false
    }

    return {
      numberRaffle,
      submitFindNumber,
      numberData,
      cancelSubmit,
      notExistNumber
    };
  },
  components: {

  },
};
</script>
<template>
  <div
      class="modal fade find-numbers-raffle"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myExtraLargeModalLabel"
      aria-hidden="true"
  >
    <div
        class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" >
            Pesquisa de números
          </h5>
          <button
              type="button"
              class="btn-close"
              id="form-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="cancelSubmit"
          ></button>
        </div>
        <form
            class="modal-body"
            :v-model="numberRaffle"
            @submit.prevent="submitFindNumber"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="mb-6">
                <label
                    class="form-label mt-3"
                    for="AddBankAccount-Name"
                >Digite o número
                </label>
                <input
                    class="form-control"
                    id="AddBankAccount-Name"
                    v-model="numberRaffle"
                />
              </div>
            </div>
          </div>
          <br>
          <h6 v-if="numberData?.status === 'FREE'">
            Status: Disponível
          </h6>
          <h6 v-if="['PAID', 'RESERVED'].includes(numberData?.status)">
            <div v-if="numberData?.customer">
              Status: {{ numberData?.status === 'RESERVED' ? 'Reservado' : 'Adquirido' }} <br>
              Nome: {{ numberData.customer.name }}<br>
              Telefone: {{ numberData.customer.whatsapp }}<br>
              E-mail: {{ numberData.customer.email }}
            </div>
            <div v-else>
              Status: Bloqueado
            </div>
          </h6>
          <h6 v-if="Object.entries(numberData).length === 0 && numberData.length !== 0 && notExistNumber">
            O Número Não Existe no Sorteio
          </h6>
          <div class="row mt-2">
            <div class="col-12 text-end">
              <button
                  type="button"
                  class="btn btn-danger me-1"
                  data-bs-dismiss="modal"
                  @click="cancelSubmit"
              >
                <i class="bx bx-x me-1"></i> Cancelar
              </button>
              <button
                  class="btn btn-success"
                  data-bs-target="#success-btn"
                  id="btn-save-event"
                  type="submit"
              >
                <i class="bx bx-check me-1"></i> Pesquisar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
